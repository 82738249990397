import React from 'react'
import { css, cx } from '../../../styled-system/css'
import { packages } from '@dup/products'
import { orderDefaultValues, useSetOrder } from '../../atoms/order.atom'
import { navigate } from 'gatsby'
import { Image, getPath } from './Image'

// TODO: Refactor this component, it needs to be more autonome and generated.

export const Albums = () => {
  const setOrder = useSetOrder()

  const handleClick = (pkgId: string) => {
    setOrder({
      ...orderDefaultValues,
      packageId: pkgId,
    })

    navigate('/photographie/order')
  }

  const getPackage = (id: string) => {
    const pkg = packages.find((_package) => _package.id === id)

    if (!pkg) {
      return null
    }

    return (
      <>
        <div
          className={css({
            pos: 'absolute',
            rotate: '45deg',
            translate: '-50% -50%',
            top: '50%',
            left: '50%',
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'rgba(255, 255, 255, .15)',
            h: 'calc(100% + 100px)',
            w: 'calc(100% + 100px)',
            cursor: 'pointer',
            transition: '.3s',
            lgDown: {
              rotate: '-0deg',
            },
            _before: {
              translate: '-50%',
              content: '""',
              pos: 'absolute',
              left: '50%',
              bottom: '0',
              h: '5%',
              bg: 'rgba(255,255,255,0.8)',
              w: '110%',
              transition: '.5s',
              zIndex: '-1',
            },
            _hover: {
              '& .line': {
                color: 'black',
                _before: {
                  bg: 'black',
                },
              },
              _before: {
                h: '100%',
              },
            },
          })}
          onClick={() => handleClick(id)}
        >
          <span
            className={cx(
              'line',
              css({
                color: 'white',
                letterSpacing: '2px',
                fontSize: '25px',
                textTransform: 'uppercase',
                fontWeight: '500',
                pos: 'relative',
                transition: '.3s',

                _before: {
                  translate: '-50%',
                  content: '""',
                  pos: 'absolute',
                  left: '50%',
                  bottom: '0',
                  h: '5%',
                  bg: 'white',
                  w: '110%',
                  transition: '.3s',
                  zIndex: '-1',
                },
              })
            )}
          >
            {pkg?.label}
          </span>
        </div>

        <Image
          relativePath={`packages/${pkg.id}/${pkg.images.at(0)?.name}`}
          className={css({
            objectFit: 'cover',
            rotate: '45deg',
            pos: 'absolute',
            translate: '-50% -50%',
            left: '50%',
            top: '50%',
            zIndex: '-1',
            minW: 'calc(100% + 100px)',
            transition: '.3s',
            lgDown: {
              rotate: '-0deg',
            },
          })}
        />
      </>
    )
  }

  const getPlaceHolderImage = (name: string) => {
    return (
      <Image
        relativePath={`home/${name}`}
        className={css({
          objectFit: 'cover',
          rotate: '45deg',
          pos: 'absolute',
          translate: '-50% -50%',
          left: '50%',
          top: '50%',
          zIndex: '-1',
          minW: 'calc(100% + 60px)',
          transition: '.3s',
          lgDown: {
            rotate: '-0deg',
          },
        })}
      />
    )
  }

  return (
    <div
      className={css({
        pos: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
      style={{
        background: "url('" + getPath('home/bg.png') + "')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '1fr 1fr 1fr 1fr',
          gap: 3,
          gridAutoFlow: 'row',
          justifyItems: 'stretch',
          gridTemplateAreas: "'GROSSESSE . .' 'COUPLE BEBE CHILDS1' 'CHILDS2 NOEL NAISSANCE' '. CHILDS3 BAPTEME'",
          zIndex: 99,
          rotate: '-45deg',
          transition: '.3s',

          lgDown: {
            scale: 0.95,
            gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
            rotate: '-0deg',
            gridTemplateAreas: "'GROSSESSE COUPLE' 'BEBE NOEL' 'NAISSANCE BAPTEME'",

            gridTemplateRows: '1fr',
            '& .CHILDS1, & .CHILDS2, & .CHILDS3': {
              display: 'none !important',
            },

            '& > div': {
              w: '200px',
            },
          },

          '& > div': {
            pos: 'relative',
            overflow: 'hidden',
            width: '200px',
            height: '200px',

            '& > div': {
              pos: 'relative',
              overflow: 'hidden',
            },
          },
        })}
      >
        <div className="GROSSESSE">{getPackage('pregnancy')}</div>
        <div className="COUPLE">{getPackage('couple')}</div>
        <div className="BEBE">{getPackage('babys')}</div>
        <div className="NOEL">{getPackage('noel')}</div>
        <div className="NAISSANCE">{getPackage('birth')}</div>
        <div className="BAPTEME">{getPackage('baptism')}</div>

        <div className="CHILDS1">
          <div className="PHOTO1">{getPlaceHolderImage('placeholder1.png')}</div>
        </div >
        <div className="CHILDS2">
          <div className="PHOTO1">{getPlaceHolderImage('placeholder2.png')}</div>
          <div className="PHOTO2">{getPlaceHolderImage('placeholder3.png')}</div>
          <div className="PHOTO3">{getPlaceHolderImage('placeholder4.png')}</div>
        </div>
        <div className="CHILDS3">
          <div className="PHOTO1">{getPlaceHolderImage('placeholder5.png')}</div>
          <div className="PHOTO2">{getPlaceHolderImage('placeholder6.png')}</div>
          <div className="PHOTO3">{getPlaceHolderImage('placeholder7.png')}</div>
        </div>
      </div >
    </div >
  )
}
